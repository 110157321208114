html, body {
  min-height: 100%;
  height: 100%;
}

.transition-300, #change-avatar-area .change-avatar-btn, .multi-table tr, offline #offline-msg, sample-tile-overview .more-details, sample-details-view .image-preview, sample-details-view .image-preview .edit-sketch-btn, price-tile-overview .more-details, .sticky-notifications, .sticky-notifications .content, .checkout-meshes {
  transition: all 300ms ease !important;
}

.transition-1000 {
  transition: all 1s ease;
}

.text-accent {
  color: #222222;
}

.text-warning {
  color: #ff6400 !important;
}

.text-success {
  color: #00e600;
}

.text-danger {
  color: #ff6666;
}

.text-fade {
  opacity: 0.5;
}

.text-center {
  text-align: center;
}

.text-deprecated {
  text-decoration: line-through;
}

.no-bg {
  background: transparent;
}

.no-margin {
  margin: 0;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
}

.bg-danger {
  background: rgba(255, 0, 0, 0.1);
}

.bg-success {
  background: rgba(0, 128, 0, 0.1);
}

.padding {
  padding: 20px;
}

.padding-half {
  padding: 10px;
}

a, a:active, a:visited {
  color: #8b0000;
  text-decoration: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 15px;
}

@media (min-width: 960px) {
  .wrapper {
    padding: 40px;
  }
}

.full-page {
  position: relative;
  min-height: 100%;
}

.full-page .full-page-content {
  position: relative;
  z-index: 10;
}

.full-page .full-page-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.full-page .full-page-background:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: ' ';
  background-color: #222;
  opacity: 0.85;
}

.editable-wrap {
  width: 100% !important;
}

.avatar {
  max-width: 60px;
  max-height: 60px;
  border-radius: 600px;
}

.avatar-sm {
  max-width: 40px;
  max-height: 40px;
  border-radius: 40px;
}

.avatar-xs {
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
}

.avatar-xxs {
  max-width: 25px;
  max-height: 25px;
  border-radius: 25px;
}

.avatar-big {
  max-width: 100px;
  max-height: 100px;
  border-radius: 100px;
}

#loading-bar .bar {
  background: #222222;
}

#loading-bar-spinner .spinner-icon {
  border-top-color: #222222 !important;
  border-left-color: #222222 !important;
  color: #222222 !important;
}

body {
  background-color: white;
}

md-content {
  background-color: transparent;
}

md-checkbox {
  margin: 0px;
}

.main-toolbar md-icon {
  color: white !important;
}

.second-toolbar {
  background-color: white !important;
  color: black !important;
}

.second-toolbar md-icon {
  color: black !important;
}

.second-toolbar h1 {
  font-size: 22px;
  font-weight: 500;
}

.second-toolbar .selection-bar {
  font-size: 14px;
}

.second-toolbar .active {
  border-bottom: #222222 2px solid;
}

.second-toolbar .filters-bar {
  border-radius: 3px;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.second-toolbar .filters-bar .filter-item {
  position: relative;
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.second-toolbar .filters-bar .filter-item:not(:first-child) {
  border-left: solid 1px rgba(221, 221, 221, 0.5);
}

.second-toolbar .filters-bar .filter-item .search {
  border: 0;
  padding: 5px 10px;
  width: 100%;
  background-color: transparent;
  outline: none;
}

.second-toolbar .filters-bar .filter-item md-select {
  margin: 0;
  font-size: 14px;
}

.second-toolbar .filters-bar .filter-item md-select md-select-value {
  border: 0;
}

md-select-header {
  padding-left: 10.667px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: auto;
  border-bottom: solid 1px #eee;
}

md-select-header input[type="search"] {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0 5px;
}

.second-toolbar .filters-bar .filter-item .md-datepicker-input-container {
  border: 0;
}

.second-toolbar .filters-bar .filter-item .md-datepicker-input {
  min-width: 100px;
}

.second-toolbar .filters-bar .filter-item .remove-filter {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -2px;
  margin-right: 2px;
  padding: 0 !important;
  width: 20px;
  height: 20px !important;
  min-height: 0;
  opacity: 0.7;
}

.second-toolbar .filters-bar .filter-item .remove-filter md-icon {
  margin-top: 2px;
  margin-left: -2px;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: white !important;
}

.filter-calendar {
  margin: 0 20px;
  border: solid 1px #ccc;
}

.selection-bar {
  background: whitesmoke;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  max-height: 40px;
}

.app-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}

#viewport {
  background-color: #f0f0f0;
}

#viewport > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

#sidebar {
  background-color: #fafafa;
  max-width: 225px;
}

#sidebar md-content {
  background-color: transparent !important;
  padding: 15px;
  font-size: 16px;
}

#sidebar .simple-item {
  margin: 10px;
  padding: 5px;
  cursor: pointer;
  outline: none;
}

#sidebar .simple-item md-icon {
  margin: 0px;
  width: auto;
  height: auto;
  font-size: 26px;
}

#sidebar .simple-item span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}

#sidebar .active md-icon {
  color: darkred;
}

#sidebar .active span {
  color: black;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}

#change-avatar-area {
  background-color: #efefef;
  background-size: cover;
  background-position: center center;
  min-width: 300px;
  min-height: 300px;
  max-width: 300px;
  max-height: 300px;
  border-radius: 300px;
  position: relative;
  margin-bottom: 30px;
}

#change-avatar-area .change-avatar-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 300px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.3;
  cursor: pointer;
}

#change-avatar-area:hover .change-avatar-btn {
  opacity: 1;
}

.menu-ref {
  outline: none;
  color: black !important;
}

.user-bulk-actions {
  margin: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 30px !important;
}

users-list .filters-bar {
  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  padding: 0 10px;
}

users-list .filters-bar input[type=text] {
  border: 0;
  height: 100%;
  padding: 0 10px;
  min-height: 40px;
  outline: none;
}

users-list .filters-bar > * {
  margin: 0 5px;
}

.multi-table {
  width: 100%;
  border-collapse: collapse;
  font-weight: 300;
}

.multi-table a, .multi-table a:visited, .multi-table a:active {
  color: inherit;
  text-decoration: none;
  font-weight: normal;
}

.multi-table tr.selected {
  background: rgba(34, 34, 34, 0.06);
}

.multi-table tr td, .multi-table tr th {
  padding: 8px 20px;
  margin: 0;
  border: 0;
}

.multi-table tr td.space, .multi-table tr th.space {
  padding-left: 40px;
  padding-right: 40px;
}

.multi-table tr td.minimun, .multi-table tr th.minimun {
  width: 1px !important;
}

.multi-table tr td.checkbox, .multi-table tr th.checkbox {
  padding-right: 0;
}

.multi-table tr td.checkbox md-checkbox, .multi-table tr th.checkbox md-checkbox {
  margin: 0;
  width: auto;
}

.multi-table tr td.no-break, .multi-table tr th.no-break {
  white-space: nowrap;
}

.multi-table tr td .md-button.md-icon-button, .multi-table tr th .md-button.md-icon-button {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  width: auto;
}

.multi-table tr.totals div {
  display: inline-block;
  margin: 0 20px;
}

.multi-table tr.totals div span:first-child {
  text-transform: uppercase;
}

.multi-table tr.totals div span:last-child {
  padding-left: 10px;
  font-weight: bold;
}

.multi-table tr:not(:last-child) td {
  border-bottom: solid 1px #eee;
}

.multi-table.editable td {
  position: relative;
}

.multi-table.editable td input[type=text], .multi-table.editable td input[type=number], .multi-table.editable td input[type=email], .multi-table.editable td input[type=password], .multi-table.editable td textarea {
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  min-height: 40px;
  outline: none;
}

.multi-table.editable td textarea {
  padding-top: 8px;
}

.multi-table.editable td input[type=number] {
  text-align: center;
}

.multi-table.editable td.has-error {
  border-bottom: solid #ffc966 2px;
}

.multi-table.editable td md-input-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.multi-table.editable td md-input-container.md-input-has-value label {
  display: none;
}

.multi-table.editable td md-input-container .md-errors-spacer {
  display: none;
}

offline {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

offline #offline-msg {
  background: #222;
  padding: 20px;
  margin-top: -100px;
  letter-spacing: 1px;
  font-size: 18px;
}

offline #offline-msg, offline #offline-msg md-icon {
  color: #ff9999;
}

offline #offline-msg.active {
  margin-top: 0;
}

.circle-img {
  background-color: rgba(255, 255, 255, 0.5);
  border: solid 2px rgba(255, 255, 255, 0.6);
}

settings-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

#samples-grid-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media (max-width: 599px) {
  #samples-grid-view {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

#samples-grid-view .grid-tile-wrapper {
  position: relative;
  margin: 5px;
}

#samples-grid-view .grid-tile-wrapper:hover md-checkbox {
  opacity: 1;
}

#samples-grid-view .grid-tile-wrapper md-checkbox {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0 30px 0;
  color: white;
  border-color: white;
}

#samples-grid-view .grid-tile-wrapper md-checkbox.selected {
  opacity: 1;
}

#samples-grid-view .grid-tile-wrapper md-checkbox .md-container {
  left: 10px;
  top: 20px;
}

#samples-grid-view .grid-tile-wrapper md-checkbox .md-container .md-icon {
  border-color: inherit;
}

#samples-grid-view .grid-tile-wrapper md-checkbox .md-label {
  font-size: 10px;
  text-transform: uppercase;
  left: 10px;
}

#samples-grid-view .grid-tile-wrapper:not(.selected) md-checkbox {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.3+25,0+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 25%, transparent 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d000000', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
}

#samples-grid-view .grid-tile-wrapper:not(.selected) md-checkbox .md-icon {
  border-color: white;
}

#samples-grid-view .grid-tile-wrapper:not(.selected) md-checkbox:hover {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8b0000+0,000000+100&0.4+35,0+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(139, 0, 0, 0.4) 0%, rgba(90, 0, 0, 0.4) 35%, transparent 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#668b0000', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
}

#samples-grid-view .grid-tile-wrapper.selected md-checkbox {
  opacity: 1;
  height: 100%;
  background-color: rgba(139, 0, 0, 0.3);
}

.selecting #samples-grid-view md-checkbox {
  height: 100%;
  opacity: 1;
}

#samples-grid-view sample-tile-overview {
  cursor: pointer;
}

#samples-grid-view sample-tile-overview:hover {
  border-color: rgba(139, 0, 0, 0.2);
}

.badge {
  background: #ccc;
  border-radius: 3px;
  padding: 1px 4px;
}

.badge.badge-warn {
  background: #ff6400;
  color: white;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 0.7em;
  text-transform: uppercase;
}

samples-display-grid md-select {
  min-width: 200px;
  margin: 0;
}

samples-display-grid md-select-value .md-text {
  width: 100% !important;
}

.sort-select {
  min-width: 200px;
}

.sort-select md-option .md-text {
  width: 100% !important;
}

samples-list-view .view-type-buttons .md-button {
  margin: 0;
  border: 0;
}

samples-list-view .view-type-buttons .md-button md-icon {
  color: #aaa !important;
}

samples-list-view .view-type-buttons .md-button.active md-icon {
  color: #8b0000 !important;
}

.select-link {
  position: absolute;
  top: 22px;
  font-size: 12px;
  color: #8b0000 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

sample-tile-overview {
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: solid 1px #ddd;
  height: 300px;
  width: 210px;
  border-radius: 0 0 6px 6px;
}

sample-tile-overview .img-overview {
  position: relative;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.3);
}

sample-tile-overview .references {
  padding: 10px 5px;
  border-radius: 0 0 6px 6px;
}

sample-tile-overview .more-details {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #222;
  opacity: 0;
  color: white;
  padding: 40px 10px 10px 10px;
  font-size: 13px;
}

sample-tile-overview .more-details h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
}

sample-tile-overview .more-details p {
  margin-top: 0;
  font-weight: bold;
  letter-spacing: 1px;
  margin-left: 2px;
  margin-bottom: 2px;
}

sample-tile-overview .more-details:hover {
  opacity: 0.95;
}

samples-display-list md-checkbox, notifications-view md-checkbox, report-details md-checkbox {
  opacity: 0.2;
}

samples-display-list md-checkbox .md-label, notifications-view md-checkbox .md-label, report-details md-checkbox .md-label {
  display: none;
}

samples-display-list md-checkbox:hover, notifications-view md-checkbox:hover, report-details md-checkbox:hover {
  opacity: 1;
}

samples-display-list tr, notifications-view tr, report-details tr {
  position: relative;
  outline: none !important;
}

samples-display-list tr:not(.selected):hover td, notifications-view tr:not(.selected):hover td, report-details tr:not(.selected):hover td {
  background-color: #eee;
}

samples-display-list tr.selected, notifications-view tr.selected, report-details tr.selected {
  background-color: rgba(139, 0, 0, 0.3) !important;
}

samples-display-list tr.fast, notifications-view tr.fast, report-details tr.fast {
  background-color: #A9F859;
}

samples-display-list .selecting tr:not(:first-child):not(.selected), notifications-view .selecting tr:not(:first-child):not(.selected), report-details .selecting tr:not(:first-child):not(.selected) {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+10&0.1+0,0+10 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0%, transparent 6%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a000000', endColorstr='#00000000', GradientType=1);
  /* IE6-9 */
}

samples-display-list .selecting md-checkbox, notifications-view .selecting md-checkbox, report-details .selecting md-checkbox {
  opacity: 1;
}

samples-display-list th, notifications-view th, report-details th {
  font-size: 15px;
  background-color: rgba(139, 0, 0, 0.1);
  letter-spacing: 1px;
  opacity: 0.8;
  padding: 15px 5px !important;
  border-right: solid 1px rgba(139, 0, 0, 0.15) !important;
}

samples-display-list th[ng-click], notifications-view th[ng-click], report-details th[ng-click] {
  outline: none;
  cursor: pointer;
}

samples-display-list td, notifications-view td, report-details td {
  outline: none;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
  border-bottom: solid 1px #eee;
  padding: 13px 5px !important;
  cursor: pointer;
}

samples-display-list td:first-child, notifications-view td:first-child, report-details td:first-child {
  width: 35px;
  min-width: 35px;
  max-width: 35px;
}

samples-display-list td md-select, notifications-view td md-select, report-details td md-select {
  margin: 0 !important;
}

samples-display-list td input[type=date], notifications-view td input[type=date], report-details td input[type=date] {
  border: 0;
  border-bottom: solid 1px #ddd;
  background-color: transparent;
}

notifications-view .filter-item input[type=date] {
  font-size: 14px;
  color: #999;
}

notifications-view table tr td {
  cursor: default;
  width: 1%;
  min-width: 150px;
}

notifications-view table tr td:first-child {
  width: auto;
  text-align: left;
}

notifications-view table tr td:first-child, notifications-view table tr th:first-child {
  padding-left: 20px !important;
}

.new-brand {
  padding: 20px;
  font-weight: 300;
}

.new-brand md-input-container {
  margin: 10px;
}

.blank-image .change-avatar-btn {
  opacity: 0.8 !important;
}

.new-finishing md-input-container .md-errors-spacer {
  display: none;
}

.with-avatar .change-avatar-btn {
  opacity: 0 !important;
}

.with-avatar:hover .change-avatar-btn {
  opacity: 1 !important;
}

sample-details-view h3 {
  margin-bottom: 60px;
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 22px;
}

sample-details-view .client-reference {
  font-size: 25px;
  font-weight: 300;
  opacity: 0.6;
  letter-spacing: 2px;
  border: solid 1px #ccc;
  background-color: #ddd;
  border-radius: 6px;
  padding: 10px 20px;
  margin-top: 50px;
  margin-bottom: 0;
}

sample-details-view md-card-content {
  padding-left: 0;
  padding-right: 0;
}

sample-details-view md-card-content > div {
  padding: 10px 30px;
  border-right: solid 1px #eee;
}

sample-details-view md-card-content > div:last-child {
  border-right: 0;
}

sample-details-view .image-preview {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

sample-details-view .image-preview:hover {
  border-radius: 30px;
}

sample-details-view .image-preview .edit-sketch-btn {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  border-radius: 30px;
  background-color: #222;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 300;
  color: white;
}

sample-details-view .image-preview .edit-sketch-btn:hover {
  opacity: 0.8;
}

sample-details-view .image-preview .edit-sketch-btn.dragover {
  opacity: 0.8;
  border: dashed 5px #8b0000;
}

sample-details-view .history {
  margin: 40px;
}

sample-details-view .history h2 {
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 3px;
  opacity: 0.7;
  margin-bottom: 0;
  padding: 5px 20px;
}

sample-details-view .history .states {
  position: relative;
  overflow: hidden;
}

sample-details-view .history .states .line {
  position: absolute;
  height: 100%;
  width: 2px;
  left: 50%;
  margin-left: -1px;
  background-color: #999999;
  z-index: 5;
  margin-top: 40px;
  margin-bottom: -40px;
}

sample-details-view .history .states .state-item {
  position: relative;
  z-index: 10;
  margin: 40px;
  background-color: #eee;
  border: solid 2px #999999;
  border-radius: 6px;
  padding-top: 0;
  max-width: 450px;
}

sample-details-view .history .states .state-item:after {
  content: ' ';
  position: absolute;
  bottom: -11px;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #999999;
}

sample-details-view .history .states .state-item:last-child {
  margin-bottom: 0;
}

sample-details-view .history .states .state-item.orange {
  border-color: #cacaca !important;
}

sample-details-view .history .states .state-item.orange p.state-name {
  background-color: #FFA500 !important;
}

sample-details-view .history .states .state-item.white {
  border-color: #cacaca !important;
}

sample-details-view .history .states .state-item.white p.state-name {
  background-color: white !important;
  color: #4a4a4a;
}

sample-details-view .history .states .state-item.yellow {
  border-color: #cacaca !important;
}

sample-details-view .history .states .state-item.yellow p.state-name {
  background-color: #FFF033 !important;
  color: #4a4a4a;
}

sample-details-view .history .states .state-item.blue {
  border-color: #cacaca !important;
}

sample-details-view .history .states .state-item.blue p.state-name {
  background-color: #336EFF !important;
}

sample-details-view .history .states .state-item.green {
  border-color: #cacaca !important;
}

sample-details-view .history .states .state-item.green p.state-name {
  background-color: #3AFD46 !important;
  color: #4a4a4a;
}

sample-details-view .history .states .state-item.red {
  border-color: #cacaca !important;
}

sample-details-view .history .states .state-item.red p.state-name {
  background-color: #F75D56 !important;
  color: #4a4a4a;
}

sample-details-view .history .states .state-item p {
  text-align: center;
  margin-top: 0px;
  opacity: 0.8;
  display: block !important;
  width: 100% !important;
  color: white;
  padding: 5px 0;
  background-color: #9b9b9b;
}

sample-details-view .history .states .state-item p.state-name {
  font-size: 20px;
  text-transform: uppercase;
  padding: 5spx 0;
}

sample-details-view .history .states .state-item p.state-name span {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}

sample-details-view .history .states .state-item p.check-diff {
  color: #4a4a4a;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
}

sample-details-view .history .states .state-item p.state-observations {
  color: #4a4a4a;
  background-color: transparent;
  font-weight: 300;
  text-align: center;
}

sample-details-view .history .states .state-item .files-wrapper div {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin: 0;
  padding: 0 10px;
}

sample-details-view .history .states .state-item .files-wrapper a {
  color: #4a4a4a;
  font-size: 10px;
  text-decoration: none;
  cursor: pointer;
}

sample-details-view .history .states .state-item .files-wrapper a:hover {
  text-decoration: underline;
}

sample-details-view .history .states .state-item span.files {
  font-size: 13px;
  color: #aaa;
  margin-right: -45px;
}

sample-details-view .history .states .state-item div {
  text-align: center;
  font-size: 24px;
  letter-spacing: 1px;
  color: #8b0000;
  text-transform: uppercase;
  padding: 0 40px;
  margin: 15px 0;
}

sample-details-view .history .states .state-item div.check-times {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: initial;
}

sample-details-view .history .states .state-item small {
  display: block;
  width: 100%;
  text-align: center;
  color: #aaa;
  margin: 0 0 5px 0;
}

sample-details-view .history .states .details-bar {
  padding: 20px;
  padding-top: 40px;
  background: white;
  border-right: solid rgba(34, 34, 34, 0.1) 3px;
}

sample-details-view .history .states md-toolbar a {
  color: #8b0000 !important;
}

.export-to-pdf-btn {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.export-to-pdf-btn md-icon {
  opacity: 0.5 !important;
}

@media print {
  -webkit-print-color-adjust: exact !important;
  .grid-tile-wrapper {
    page-break-before: always !important;
  }
  md-toolbar {
    display: none;
  }
  #printable {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .ordering-samples {
    display: none;
  }
  paginator {
    display: none;
  }
  #viewport {
    background: white;
    -webkit-print-color-adjust: exact;
  }
  md-fab-speed-dial {
    display: none;
  }
}

.brand-title {
  margin: 15px 0 25px 0;
}

.brand-title h1 {
  margin: 0 15px !important;
}

.brand-title h3 {
  margin: 0 !important;
}

notifications-list md-list-item p {
  padding: 15px 0;
  font-size: 14px;
  color: #555;
  line-height: 20px;
}

notifications-list md-list-item:not(:last-child) {
  border-bottom: solid 1px #eee;
}

.tab-bar {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding-left: 60px;
}

.tab-bar .md-button {
  border-radius: 0;
  border-width: 0;
  border-color: transparent;
  color: inherit;
  margin-bottom: 0;
}

.tab-bar .md-button.active {
  color: #8b0000;
  border-bottom: solid #8b0000 3px;
}

notifications-view md-list-item p {
  padding: 15px 0;
  font-size: 14px;
  color: #555;
  line-height: 20px;
}

notifications-view md-list-item:not(:last-child) {
  border-bottom: solid 1px #eee;
}

notifications-view md-card {
  margin: 20px 0;
}

.notofications-badge {
  position: absolute;
  top: 0;
  font-size: 18px;
  right: -5px;
  background-color: #ff6400;
  border-radius: 50px;
  padding: 0px 8px;
  color: white;
  font-weight: bold;
}

.no-sketch-msg {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.3);
}

selectable-list md-toolbar .md-toolbar-tools {
  position: relative;
  background-color: white;
  padding: 0;
}

selectable-list md-toolbar .md-toolbar-tools md-icon {
  color: #888;
  margin-left: 15px;
  margin-right: 5px;
}

selectable-list md-toolbar .md-toolbar-tools input {
  height: 100%;
  border: 0;
  outline: none;
}

selectable-list md-list-item {
  position: relative;
}

selectable-list md-list-item.active {
  background-color: #8b0000;
  color: white;
}

sample-types-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

select-and-edit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

sample-type-details-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

sample-type-details-view md-card {
  border: solid 2px #8b0000;
}

.date-picker._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  left: 34px;
  right: 0;
}

.date-picker md-datepicker {
  display: inline-block;
  padding: 0;
  width: 100%;
}

.date-picker md-datepicker .md-datepicker-button {
  margin-left: -12px;
}

.date-picker md-datepicker .md-datepicker-input-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.date-picker md-datepicker .md-datepicker-input-container .md-datepicker-input {
  max-width: 100%;
  min-width: 100%;
}

.date-picker md-datepicker .md-datepicker-input-container .md-datepicker-triangle-button {
  right: 8px;
}

.date-picker .md-input-messages-animation .md-input-message-animation {
  margin-left: 34px;
}

.notes-area {
  min-width: 200px;
  border-bottom: solid 1px #ececec !important;
}

.no-label label {
  display: none;
}

.state-badge {
  display: inline-block;
  padding: 1px 4px;
}

.sample-row {
  position: relative;
  z-index: 5;
}

md-switch[disabled=disabled] {
  color: #999;
}

.hide-inner-text-fade .text-fade {
  display: none;
}

md-bottom-sheet {
  bottom: 80px;
  padding-bottom: 0;
}

md-bottom-sheet h3 {
  font-size: 22px;
  margin-bottom: 5px;
}

md-bottom-sheet h3 small {
  font-size: 14px;
}

md-bottom-sheet table {
  width: 100%;
  border: solid 1px #eee;
}

md-bottom-sheet table th, md-bottom-sheet table td {
  border-right: solid 1px #eee;
  padding: 10px;
}

md-bottom-sheet table th:last-child, md-bottom-sheet table td:last-child {
  border-right: 0;
}

md-bottom-sheet table.accent th, md-bottom-sheet table.accent td {
  background-color: #F5EAEB;
}

md-bottom-sheet th {
  font-weight: normal;
  opacity: 0.8;
}

.sample-section {
  margin-top: 60px;
  margin-bottom: 10px;
}

.sample-section-desc {
  margin-top: 10px;
  color: #aaaaaa;
  font-size: 12px;
  margin-bottom: 60px;
}

.updated-info {
  margin-top: 10px;
  margin-bottom: 25px;
}

.updated-info small {
  opacity: 0.5;
}

.week {
  font-size: 12px;
  padding-right: 8px;
}

.load-progress {
  margin-left: 10px;
  margin-right: 10px;
}

.workload-stats {
  padding-left: 8px;
  font-size: 12px;
  padding-right: 20px;
}

.progress-bar {
  height: 16px;
  border: 1px;
  width: 100px;
  background-color: #cccccc;
  border-radius: 2px;
}

.progress-bar-indicator {
  height: 16px;
  border-radius: 2px;
  max-width: 100%;
}

.low-load {
  background-color: #3AFD46;
}

.medium-load {
  background-color: #FFF033;
}

.high-load {
  background-color: #F75D56;
}

.notification-item:hover {
  background-color: #F6EBEB;
  cursor: pointer;
}

.notification-message-unread {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.notification-message-read {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.prices-table {
  border-collapse: collapse;
  white-space: nowrap;
  width: 100%;
}

.prices-table input:disabled {
  border-bottom: none;
}

.prices-table input[type="search"] {
  text-align: left;
  width: 95%;
}

.prices-table th, .prices-table td {
  border-bottom: 1px solid #eee;
  text-align: right;
  padding: 13px 0 !important;
}

.prices-table th {
  background-color: rgba(139, 0, 0, 0.1);
}

.prices-table tr.accessory {
  background-color: #ecffed;
}

.prices-table tr.accessory:hover {
  background-color: #e2ffe3;
}

.prices-table tr.operation {
  background-color: #edffff;
}

.prices-table tr.operation:hover {
  background-color: #e3ffff;
}

.prices-table input {
  width: 100px;
  border: 0;
  outline: none;
  background: none;
  border-bottom: 1px solid #aaa;
  text-align: right;
}

.prices-table tr .action {
  visibility: hidden;
}

.prices-table tr:hover .action {
  visibility: visible;
}

.repeat-item.ng-enter,
.repeat-item.ng-leave {
  transition: 0.2s linear all;
}

.repeat-item.ng-enter,
.repeat-item.ng-leave.ng-leave-active {
  opacity: 0;
}

.repeat-item.ng-leave,
.repeat-item.ng-enter.ng-enter-active {
  opacity: 1;
}

.sample-price-dialog {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 500px;
}

.sample-price-dialog-content {
  padding: 0 15px;
  width: 100%;
}

.sample-price-dialog-button {
  width: 100%;
}

.has-content {
  color: #850012 !important;
  opacity: .65;
}

price-tile-overview {
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: solid 1px #ddd;
  height: 300px;
  width: 210px;
  cursor: pointer;
  border-radius: 0 0 6px 6px;
}

price-tile-overview .img-overview {
  position: relative;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.3);
}

price-tile-overview .references {
  padding: 10px 5px;
  border-radius: 0 0 6px 6px;
}

price-tile-overview .more-details {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #222;
  opacity: 0;
  color: white;
  padding: 40px 10px 10px 10px;
  font-size: 13px;
}

price-tile-overview .more-details h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
}

price-tile-overview .more-details p {
  margin-top: 0;
  font-weight: bold;
  letter-spacing: 1px;
  margin-left: 2px;
  margin-bottom: 2px;
}

price-tile-overview .more-details:hover {
  opacity: 0.95;
}

.exp-price-tile {
  background-color: red;
}

.price-formula-field {
  border-bottom-style: dashed !important;
}

dashboard p.development {
  font-size: 24px;
  color: #cacaca;
}

.dashboard-prices {
  height: 100%;
  margin-top: 25px;
}

.dashboard-prices .full-width {
  width: 100%;
}

.dashboard-prices .item-row {
  height: 30px;
  font-size: 14px;
  color: #333;
}

.dashboard-prices .item-row:hover {
  background-color: #eee;
}

.dashboard-prices .brand-name {
  padding: 0 10px;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dashboard-prices .abbrev-field {
  font-weight: bold;
  color: #4a4a4a;
  background-color: #eee;
  padding: 0 5px;
  font-size: 11px;
}

.dashboard-prices .client-reference {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dashboard-prices .shipping {
  padding: 0 10px;
  text-align: center;
  color: #333;
}

.dashboard-prices .bullet {
  vertical-align: middle;
  font-size: 18px;
  color: transparent;
}

.dashboard-prices .price-warning {
  color: #c8c338;
}

.dashboard-prices .price-severe {
  color: red;
}

.dashboard-prices p.empty {
  font-size: 24px;
  color: #cacaca;
  text-align: center;
  -webkit-margin-before: 0;
          margin-block-start: 0;
}

.dashboard-prices md-card-footer {
  padding: 0 0 25px 0;
}

.dashboard-prices .last-update {
  color: #bababa;
  font-size: 14px;
}

.dashboard-prices .last-update p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.dashboard-prices .last-update md-icon {
  color: currentColor !important;
  font-size: 18px;
  margin-top: 2px;
}

.commercial-filter {
  margin-top: 0;
  font-size: 16px;
}

.brand-alias ol li {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 20px;
}

.brand-alias table {
  font-size: 14px;
  color: #4a4a4a;
  margin: 25px 0;
  border-collapse: collapse;
  vertical-align: middle !important;
}

.brand-alias table th {
  width: 35%;
  text-align: center;
}

.brand-alias table .destroy-brand-alias {
  visibility: hidden;
}

.brand-alias table tr.brand-alias-item:hover {
  background-color: #eee;
}

.brand-alias table tr.brand-alias-item:hover .destroy-brand-alias {
  visibility: visible;
}

.brand-alias table td {
  text-align: center;
}

.brand-alias .no-alias {
  padding-left: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #666;
  margin-bottom: 25px;
}

.brand-alias .new-brand-alias {
  padding-left: 20px;
  width: 100%;
}

.brand-alias .new-brand-alias input {
  width: 100%;
}

.announcement-dialog {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 750px;
  max-height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 25px;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 22px;
  -webkit-margin-before: 0;
          margin-block-start: 0;
}

.announcement-dialog .name {
  font-size: 22px;
  color: #4a4a4a;
}

.announcement-dialog img {
  display: block;
  padding: 25px 0;
  text-align: center;
  max-width: 100%;
}

.sample-price-check-timming {
  padding-top: 4px;
  font-size: 16px;
}

.nudge-list {
  font-size: 11px;
  -webkit-margin-before: 0;
          margin-block-start: 0;
}

.current-check {
  margin: 5px 0px 15px 0px;
  font-size: 12px;
  vertical-align: middle;
}

.current-check-cue {
  background-color: #3afd46;
  width: 100%;
  height: 5px;
}

.dashboard-stats {
  height: 100%;
  margin-top: 25px;
}

.dashboard-stats .controls {
  float: right;
}

.dashboard-stats .active {
  color: #8b0000 !important;
}

.dashboard-stats .inactive {
  color: rgba(0, 0, 0, 0.54) !important;
}

.dashboard-stats .stats-card {
  height: 100%;
  padding-top: 25px;
}

.dashboard-stats .last-update {
  color: #bababa;
  font-size: 14px;
}

.dashboard-stats .last-update p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.dashboard-stats .last-update md-icon {
  color: currentColor !important;
  font-size: 18px;
  margin-top: 2px;
}

.dashboard-stats-table {
  width: 100%;
  font-size: 14px;
  color: #333333;
}

.dashboard-stats-table .head {
  padding-bottom: 20px;
}

.dashboard-stats-table .table-header {
  text-align: center;
  font-weight: bold;
}

.dashboard-stats-table .metric-name {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  text-transform: uppercase;
}

.dashboard-stats-table .metric-value {
  padding-top: 10px;
  font-size: 13px;
  text-align: center;
  margin-bottom: 5px;
}

.dashboard-stats-table .metric-evolution {
  padding-bottom: 10px;
  font-size: 11px;
  text-align: center;
}

.dashboard-stat-row:hover {
  background-color: #eee;
}

.measurement-files {
  width: 90%;
  text-align: right;
  color: grey;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 10px 10px 0;
  border: 1px dashed transparent;
}

.measurement-files.dragover {
  opacity: 0.8;
  border: 1px dashed #8b0000 !important;
  color: #8b0000;
}

#sample-files {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#sample-files .sample-file {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 20px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: solid 1px #ddd;
  border-radius: 0 0 6px 6px;
}

#sample-files .sample-file:hover div {
  opacity: 0.80;
}

#sample-files .sample-file div {
  height: 25%;
  background-color: #222;
  opacity: 0;
  color: white;
}

#sample-files .sample-file section {
  opacity: 1;
  position: absolute;
  top: 170px;
  padding-left: 20px;
  color: #4a4a4a;
}

#sample-photos {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#sample-photos .sample-photo {
  width: 200px;
  height: 200px;
  margin: 20px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: solid 1px #ddd;
  border-radius: 0 0 6px 6px;
}

#sample-photos .sample-photo:hover div {
  opacity: 0.80;
}

#sample-photos .sample-photo div {
  height: 25%;
  background-color: #222;
  opacity: 0;
  color: white;
}

.sample-photo-dialog {
  background-color: #EFEFEF;
  width: 100%;
  height: 100%;
}

.sample-photo-dialog .sample-photo-modal {
  width: 100%;
  height: 100%;
}

.sample-photo-dialog .sample-photo-modal .sample-photo-wrapper {
  width: 95%;
  height: 95%;
}

.sample-photo-dialog .sample-photo-modal .sample-photo-wrapper .sample-photo-image {
  width: 95%;
  height: 95%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.sample-photo-dialog .sample-photo-modal .sample-photo-wrapper .sample-photo-pager-button {
  font-size: 38px;
}

.prices-field-updates-dialog {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 650px;
}

.prices-field-updates-dialog .prices-field-updates span {
  width: 100%;
  padding: 25px 0;
  font-size: 18px;
}

.prices-field-updates-dialog .prices-field-updates table {
  margin: 25px;
}

.prices-field-updates-dialog .prices-field-updates table tr {
  line-height: 26px;
}

.prices-field-updates-dialog .prices-field-updates table th {
  font-weight: 600;
  padding: 25px 45px;
}

.prices-field-updates-dialog .prices-field-updates table td {
  font-size: 14px;
  text-align: center;
  opacity: 0.8;
}

.prices-field-updates-dialog .prices-field-updates button {
  margin-bottom: 25px;
}

.sample-tile-footer {
  margin: 15px 10px 0 10px;
  -ms-flex-item-align: start;
      -ms-grid-row-align: start;
      align-self: start;
  width: 100%;
}

.tile-action-button {
  padding: 0 !important;
  margin: 0 !important;
}

.tile-button-filled {
  font-size: 20px;
  color: #8b0000;
  opacity: 0.9;
  margin: 0 !important;
  padding: 0 !important;
}

.tile-button-filled .tile-button-icon {
  font-size: 20px;
  color: #666;
}

.tile-button-filled .tile-button-icon-filled {
  font-size: 20px;
  color: #006385;
  opacity: 0.9;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sample-development-cycle-time-selector .md-select-value {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}

.workspace-item-config {
  border: 1px solid #ccc;
  padding: 0 10px 10px 10px;
  margin: 10px 5px;
  width: 150px;
  font-size: 12px;
}

.workspace-item-config p {
  width: 98%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.workspace-item-config:hover {
  background-color: #F5EAEB;
}

.workspace-item-config.disabled {
  background-color: #eee;
  color: #888;
}

#support-page h3 {
  font-size: 28px;
  color: #4a4a4a;
}

#support-page .support-grid {
  width: 60%;
}

#support-page .support-grid .support-topic {
  position: relative;
  width: 40%;
  padding: 25px;
  margin: 25px;
  border: 1px solid #8b0000;
  color: #8b0000;
  transition: box-shadow .25s linear;
}

#support-page .support-grid .support-topic:hover {
  box-shadow: 0 5px 10px rgba(1, 14, 40, 0.1);
}

#support-page .support-grid .support-topic a {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.support-topic-panel {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 1000px;
}

.ticket-panel {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 600px;
  min-height: 350px;
}

.sample-development-log-observations {
  width: 500px;
  font-size: 14px;
  color: #4a4a4a;
}

.sample-menu-icon {
  margin-right: 8px !important;
}

.banner-validate-sample {
  background-color: #FAFAFA;
  border: 1px solid #ececec;
  margin-top: 25px;
  padding: 10px 50px;
  color: #676767;
  font-size: 14px;
}

.banner-validate-sample * {
  padding: 0 40px;
}

.foreseen-date-reasons {
  margin: -25px 0 50px 0;
  max-width: 350px;
}

.foreseen-date-reason {
  background-color: #FAFAFA;
  border: 1px solid #ececec;
  padding: 15px;
  color: #676767;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.sample-state-files {
  width: 100%;
  margin-bottom: 12px;
}

.sample-state-files .sample-state-file-preview {
  width: 90%;
  background-color: #f2f2f2;
  color: grey;
  font-size: 12px;
  padding: 5px;
  margin: 5px 0;
}

.checklist {
  margin-bottom: 36px;
}

.checklist .checklist-item {
  margin: 24px 0 0 0px;
}

.checklist .checklist-item span {
  margin-left: 8px;
}

.transition-modal {
  width: calc(100% - 64px);
  max-width: 600px;
  background-color: #fff;
  border-radius: 4px;
}

.transition-modal .toolbar {
  padding: 18px 24px 0 24px;
}

.transition-modal .content {
  padding: 0px 64px 64px 64px;
  color: #4d4d4d;
}

.transition-modal .content h1 {
  font-weight: 700;
  font-size: 28px;
}

.transition-modal .content p {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.6em;
}

.sticky-notifications {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #fff;
  border-radius: 4px;
  z-index: 99999999999;
}

.sticky-notifications .content {
  width: 250px;
  background-color: #fff;
  border: 2px solid #e4e4e4;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
}

.sticky-notifications .content p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.6em;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.show-track-changes {
  color: #444444;
  font-size: 20px;
  cursor: pointer;
}

.mesh-details-wrapper {
  margin-bottom: 30px;
}

.checkout-meshes .mesh-details {
  width: 85%;
  font-size: 16px !important;
  font-weight: 400 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.sample-mesh-locations {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.sample-mesh-locations .container-name {
  color: #4d4d4d;
  font-size: 14px !important;
  font-weight: 600;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin-right: 10px;
}

.mesh-consumptions .no-margin {
  margin: 0 !important;
}

